<template>
  <svg width="105" height="96" viewBox="0 0 105 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#ccs-clip0)">
      <path
        d="M89.7741 8.73641L2.48877 34.1677L4.80071 42.1028L92.0861 16.6714L89.7741 8.73641Z"
        fill="#333333"
      />
      <path
        d="M12.9199 70.6188L0.054741 26.463C-0.0406379 26.1357 -0.00205992 25.7838 0.161982 25.4849C0.326025 25.186 0.602095 24.9644 0.929465 24.8691L86.0989 0.0542246C86.4263 -0.0408338 86.778 -0.00212509 87.0768 0.161858C87.3757 0.325841 87.5972 0.6017 87.6929 0.92888L99.0905 40.0477L98.7378 40.1505L87.3402 1.03165C87.2717 0.798051 87.1134 0.601121 86.9 0.484008C86.6866 0.366895 86.4355 0.339147 86.2016 0.406846L1.03222 25.2217C0.79838 25.2898 0.601188 25.448 0.484015 25.6616C0.366842 25.8751 0.339286 26.1264 0.407411 26.3603L13.2726 70.516L12.9199 70.6188Z"
        fill="#3F3D56"
      />
      <path
        d="M80.2366 40.2269C79.9782 40.2266 79.7269 40.1427 79.5201 39.9876C79.3134 39.8326 79.1625 39.6148 79.0899 39.3668L76.9321 31.9607C76.8439 31.6568 76.8799 31.3302 77.0322 31.0528C77.1844 30.7753 77.4405 30.5695 77.7443 30.4806L85.1504 28.3228C85.4543 28.2347 85.7809 28.2707 86.0584 28.4229C86.3359 28.5752 86.5416 28.8314 86.6305 29.1352L88.7883 36.5412C88.8765 36.8452 88.8405 37.1717 88.6882 37.4492C88.5359 37.7266 88.2798 37.9324 87.9761 38.0213L80.57 40.1791C80.4617 40.2107 80.3494 40.2268 80.2366 40.2269Z"
        :fill="primaryColors.primary500"
      />
      <path
        d="M12.7182 26.597C12.696 26.5209 12.6696 26.4461 12.6392 26.3729C12.4321 25.8703 12.0466 25.462 11.5567 25.2264C11.0669 24.9907 10.5072 24.9445 9.98532 25.0964C9.46342 25.2484 9.01608 25.5878 8.72926 26.0496C8.44243 26.5113 8.33637 27.0628 8.43144 27.598C8.44479 27.676 8.46258 27.7533 8.4847 27.8294C8.61882 28.2872 8.89763 28.6892 9.27939 28.9753C9.66114 29.2614 10.1253 29.4161 10.6023 29.4163C10.8106 29.4167 11.0179 29.387 11.2176 29.3281C11.7784 29.1643 12.2512 28.7847 12.5325 28.2727C12.8138 27.7608 12.8806 27.1581 12.7182 26.597ZM11.1148 28.9755C10.6472 29.1112 10.1448 29.0559 9.71787 28.8218C9.29094 28.5877 8.97429 28.1938 8.83733 27.7265C8.81583 27.6504 8.79864 27.5732 8.78586 27.4951C8.71697 27.0541 8.81105 26.6032 9.05052 26.2266C9.28998 25.8499 9.65842 25.5734 10.087 25.4487C10.5155 25.3241 10.9748 25.3597 11.3789 25.5491C11.7831 25.7384 12.1044 26.0685 12.2829 26.4776C12.3161 26.5494 12.3438 26.6237 12.3655 26.6998C12.5011 27.1675 12.4455 27.6698 12.211 28.0964C11.9765 28.5231 11.5822 28.8393 11.1148 28.9755V28.9755Z"
        :fill="primaryColors.primary500"
      />
      <path
        d="M7.31253 28.1711C7.19499 27.7674 7.20628 27.3372 7.34483 26.9402C7.48337 26.5433 7.74227 26.1995 8.08546 25.9566C7.76394 25.9409 7.44332 26.0023 7.15032 26.1357C6.85732 26.269 6.60042 26.4704 6.40103 26.7231C6.20164 26.9759 6.06552 27.2726 6.00401 27.5885C5.94251 27.9045 5.9574 28.2306 6.04745 28.5397C6.1375 28.8487 6.30009 29.1318 6.52169 29.3653C6.74329 29.5988 7.01747 29.776 7.32141 29.882C7.62534 29.9881 7.95021 30.02 8.26897 29.9751C8.58773 29.9302 8.89115 29.8098 9.15395 29.6239C8.73403 29.6034 8.33096 29.4524 8.00089 29.192C7.67083 28.9316 7.4302 28.5747 7.31253 28.1711Z"
        :fill="primaryColors.primary500"
      />
      <path
        d="M64.4073 37.2735C64.3682 37.133 64.3018 37.0015 64.2118 36.8866C64.1219 36.7718 64.0102 36.6758 63.8831 36.6041C63.756 36.5325 63.616 36.4866 63.4712 36.4691C63.3264 36.4516 63.1795 36.4628 63.039 36.5021L50.8454 39.9128L49.5322 40.2801H57.7788L59.092 39.9128L63.6377 38.6418C63.9211 38.5622 64.1613 38.3734 64.3056 38.1169C64.4499 37.8603 64.4864 37.557 64.4073 37.2735Z"
        fill="#CCCCCC"
      />
      <path
        d="M55.7806 34.7261C55.7011 34.4426 55.5124 34.2023 55.2557 34.058C54.9991 33.9136 54.6957 33.8771 54.4122 33.9565L36.1155 39.0734C35.8598 39.1459 35.6384 39.3073 35.4912 39.5285C35.3441 39.7498 35.2808 40.0163 35.3128 40.2801H40.0496L41.3646 39.9128L55.011 36.0962C55.2946 36.0163 55.5349 35.8272 55.6792 35.5703C55.8235 35.3134 55.86 35.0098 55.7806 34.7261Z"
        fill="#CCCCCC"
      />
      <path
        d="M102.884 39.9124H14.1734C13.8325 39.9127 13.5057 40.0483 13.2647 40.2893C13.0237 40.5304 12.8881 40.8572 12.8877 41.198V93.7265C12.8881 94.0674 13.0236 94.3942 13.2647 94.6352C13.5057 94.8762 13.8325 95.0118 14.1734 95.0122H102.884C103.225 95.0118 103.552 94.8762 103.793 94.6352C104.034 94.3942 104.169 94.0674 104.17 93.7265V41.198C104.169 40.8572 104.034 40.5304 103.793 40.2894C103.552 40.0483 103.225 39.9128 102.884 39.9124ZM103.803 93.7265C103.802 93.97 103.706 94.2036 103.533 94.3758C103.361 94.548 103.128 94.6447 102.884 94.6448H14.1734C13.9298 94.6447 13.6963 94.548 13.5241 94.3758C13.3519 94.2036 13.2551 93.97 13.255 93.7265V41.198C13.2551 40.9545 13.3519 40.721 13.5241 40.5488C13.6963 40.3766 13.9298 40.2798 14.1734 40.2797H102.884C103.128 40.2798 103.361 40.3766 103.533 40.5488C103.706 40.721 103.802 40.9545 103.803 41.198V93.7265Z"
        fill="#3F3D56"
      />
      <path
        d="M22.8051 47.0758C22.3692 47.0758 21.943 46.9466 21.5806 46.7044C21.2181 46.4622 20.9357 46.118 20.7688 45.7153C20.602 45.3126 20.5584 44.8694 20.6434 44.4419C20.7285 44.0143 20.9384 43.6216 21.2466 43.3134C21.5548 43.0052 21.9476 42.7952 22.3751 42.7102C22.8026 42.6252 23.2458 42.6688 23.6485 42.8356C24.0512 43.0024 24.3954 43.2849 24.6376 43.6474C24.8798 44.0098 25.0091 44.4359 25.0091 44.8718C25.0084 45.4562 24.776 46.0164 24.3628 46.4296C23.9496 46.8427 23.3894 47.0752 22.8051 47.0758ZM22.8051 43.0352C22.4418 43.0352 22.0867 43.1429 21.7847 43.3447C21.4826 43.5465 21.2472 43.8334 21.1082 44.169C20.9692 44.5046 20.9328 44.8739 21.0037 45.2302C21.0746 45.5864 21.2495 45.9137 21.5064 46.1706C21.7632 46.4274 22.0905 46.6023 22.4468 46.6732C22.803 46.7441 23.1723 46.7077 23.5079 46.5687C23.8435 46.4297 24.1304 46.1943 24.3322 45.8922C24.534 45.5902 24.6417 45.2351 24.6417 44.8718C24.6412 44.3849 24.4475 43.9181 24.1032 43.5737C23.7589 43.2294 23.292 43.0357 22.8051 43.0352Z"
        :fill="primaryColors.primary500"
      />
      <path
        d="M19.3792 44.8719C19.3793 44.4515 19.5105 44.0415 19.7545 43.6992C19.9986 43.3569 20.3433 43.0992 20.7408 42.962C20.4364 42.857 20.1114 42.8263 19.7928 42.8723C19.4742 42.9184 19.1713 43.0399 18.9091 43.2268C18.647 43.4136 18.4333 43.6604 18.2859 43.9466C18.1384 44.2327 18.0615 44.55 18.0615 44.8719C18.0615 45.1938 18.1384 45.511 18.2859 45.7972C18.4333 46.0834 18.647 46.3301 18.9091 46.517C19.1713 46.7038 19.4742 46.8254 19.7928 46.8714C20.1114 46.9175 20.4364 46.8867 20.7408 46.7817C20.3433 46.6446 19.9986 46.3869 19.7545 46.0446C19.5105 45.7022 19.3793 45.2923 19.3792 44.8719Z"
        :fill="primaryColors.primary500"
      />
      <path
        d="M31.7394 70.9116C31.3012 70.9111 30.881 70.7368 30.5711 70.4269C30.2612 70.117 30.0869 69.6968 30.0864 69.2586V64.2996C30.0864 63.8612 30.2606 63.4408 30.5706 63.1308C30.8806 62.8208 31.301 62.6466 31.7394 62.6466C32.1778 62.6466 32.5983 62.8208 32.9083 63.1308C33.2183 63.4408 33.3924 63.8612 33.3924 64.2996V69.2586C33.3919 69.6968 33.2176 70.117 32.9077 70.4269C32.5978 70.7368 32.1777 70.9111 31.7394 70.9116Z"
        :fill="primaryColors.primary500"
      />
      <path
        d="M36.1471 70.9116C35.7089 70.9111 35.2887 70.7368 34.9788 70.4269C34.669 70.117 34.4946 69.6968 34.4941 69.2586V64.2996C34.4941 63.8612 34.6683 63.4408 34.9783 63.1308C35.2883 62.8208 35.7087 62.6466 36.1471 62.6466C36.5855 62.6466 37.006 62.8208 37.316 63.1308C37.626 63.4408 37.8001 63.8612 37.8001 64.2996V69.2586C37.7996 69.6968 37.6253 70.117 37.3154 70.4269C37.0055 70.7368 36.5854 70.9111 36.1471 70.9116Z"
        :fill="primaryColors.primary500"
      />
      <path
        d="M40.5554 70.9116C40.1171 70.9111 39.697 70.7368 39.3871 70.4269C39.0772 70.117 38.9028 69.6968 38.9023 69.2586V64.2996C38.9023 63.8612 39.0765 63.4408 39.3865 63.1308C39.6965 62.8208 40.117 62.6466 40.5554 62.6466C40.9938 62.6466 41.4142 62.8208 41.7242 63.1308C42.0342 63.4408 42.2084 63.8612 42.2084 64.2996V69.2586C42.2079 69.6968 42.0336 70.117 41.7237 70.4269C41.4138 70.7368 40.9936 70.9111 40.5554 70.9116Z"
        :fill="primaryColors.primary500"
      />
      <path
        d="M47.3507 70.9116C46.9125 70.9111 46.4923 70.7368 46.1825 70.4269C45.8726 70.117 45.6983 69.6968 45.6978 69.2586V64.2996C45.6978 63.8612 45.8719 63.4408 46.1819 63.1308C46.4919 62.8208 46.9123 62.6466 47.3507 62.6466C47.7891 62.6466 48.2096 62.8208 48.5196 63.1308C48.8296 63.4408 49.0037 63.8612 49.0037 64.2996V69.2586C49.0032 69.6968 48.8289 70.117 48.519 70.4269C48.2091 70.7368 47.789 70.9111 47.3507 70.9116Z"
        :fill="primaryColors.primary500"
      />
      <path
        d="M51.759 70.9116C51.3207 70.9111 50.9006 70.7368 50.5907 70.4269C50.2808 70.117 50.1065 69.6968 50.106 69.2586V64.2996C50.106 63.8612 50.2801 63.4408 50.5901 63.1308C50.9001 62.8208 51.3206 62.6466 51.759 62.6466C52.1974 62.6466 52.6178 62.8208 52.9278 63.1308C53.2378 63.4408 53.412 63.8612 53.412 64.2996V69.2586C53.4115 69.6968 53.2371 70.117 52.9273 70.4269C52.6174 70.7368 52.1972 70.9111 51.759 70.9116Z"
        :fill="primaryColors.primary500"
      />
      <path
        d="M56.1672 70.9116C55.7289 70.9111 55.3088 70.7368 54.9989 70.4269C54.689 70.117 54.5147 69.6968 54.5142 69.2586V64.2996C54.5142 63.8612 54.6883 63.4408 54.9983 63.1308C55.3083 62.8208 55.7288 62.6466 56.1672 62.6466C56.6056 62.6466 57.026 62.8208 57.336 63.1308C57.646 63.4408 57.8201 63.8612 57.8201 64.2996V69.2586C57.8196 69.6968 57.6453 70.117 57.3354 70.4269C57.0256 70.7368 56.6054 70.9111 56.1672 70.9116Z"
        :fill="primaryColors.primary500"
      />
      <path
        d="M62.9626 70.9116C62.5243 70.9111 62.1042 70.7368 61.7943 70.4269C61.4844 70.117 61.3101 69.6968 61.3096 69.2586V64.2996C61.3096 63.8612 61.4837 63.4408 61.7937 63.1308C62.1037 62.8208 62.5242 62.6466 62.9626 62.6466C63.401 62.6466 63.8214 62.8208 64.1314 63.1308C64.4414 63.4408 64.6156 63.8612 64.6156 64.2996V69.2586C64.6151 69.6968 64.4408 70.117 64.1309 70.4269C63.821 70.7368 63.4008 70.9111 62.9626 70.9116Z"
        :fill="primaryColors.primary500"
      />
      <path
        d="M67.3707 70.9116C66.9325 70.9111 66.5124 70.7368 66.2025 70.4269C65.8926 70.117 65.7183 69.6968 65.7178 69.2586V64.2996C65.7178 63.8612 65.8919 63.4408 66.2019 63.1308C66.5119 62.8208 66.9324 62.6466 67.3707 62.6466C67.8091 62.6466 68.2296 62.8208 68.5396 63.1308C68.8496 63.4408 69.0237 63.8612 69.0237 64.2996V69.2586C69.0232 69.6968 68.8489 70.117 68.539 70.4269C68.2291 70.7368 67.809 70.9111 67.3707 70.9116Z"
        :fill="primaryColors.primary500"
      />
      <path
        d="M71.7785 70.9116C71.3402 70.9111 70.9201 70.7368 70.6102 70.4269C70.3003 70.117 70.126 69.6968 70.1255 69.2586V64.2996C70.1255 63.8612 70.2996 63.4408 70.6096 63.1308C70.9196 62.8208 71.3401 62.6466 71.7785 62.6466C72.2169 62.6466 72.6373 62.8208 72.9473 63.1308C73.2573 63.4408 73.4315 63.8612 73.4315 64.2996V69.2586C73.431 69.6968 73.2567 70.117 72.9468 70.4269C72.6369 70.7368 72.2167 70.9111 71.7785 70.9116Z"
        :fill="primaryColors.primary500"
      />
      <path
        d="M78.5744 70.9116C78.1361 70.9111 77.716 70.7368 77.4061 70.4269C77.0962 70.117 76.9219 69.6968 76.9214 69.2586V64.2996C76.9214 63.8612 77.0955 63.4408 77.4055 63.1308C77.7155 62.8208 78.136 62.6466 78.5744 62.6466C79.0128 62.6466 79.4332 62.8208 79.7432 63.1308C80.0532 63.4408 80.2274 63.8612 80.2274 64.2996V69.2586C80.2269 69.6968 80.0526 70.117 79.7427 70.4269C79.4328 70.7368 79.0126 70.9111 78.5744 70.9116Z"
        :fill="primaryColors.primary500"
      />
      <path
        d="M82.9821 70.9116C82.5438 70.9111 82.1237 70.7368 81.8138 70.4269C81.5039 70.117 81.3296 69.6968 81.3291 69.2586V64.2996C81.3291 63.8612 81.5033 63.4408 81.8133 63.1308C82.1232 62.8208 82.5437 62.6466 82.9821 62.6466C83.4205 62.6466 83.8409 62.8208 84.1509 63.1308C84.4609 63.4408 84.6351 63.8612 84.6351 64.2996V69.2586C84.6346 69.6968 84.4603 70.117 84.1504 70.4269C83.8405 70.7368 83.4203 70.9111 82.9821 70.9116Z"
        :fill="primaryColors.primary500"
      />
      <path
        d="M87.3903 70.9116C86.9521 70.9111 86.5319 70.7368 86.222 70.4269C85.9121 70.117 85.7378 69.6968 85.7373 69.2586V64.2996C85.7373 63.8612 85.9115 63.4408 86.2215 63.1308C86.5315 62.8208 86.9519 62.6466 87.3903 62.6466C87.8287 62.6466 88.2492 62.8208 88.5592 63.1308C88.8691 63.4408 89.0433 63.8612 89.0433 64.2996V69.2586C89.0428 69.6968 88.8685 70.117 88.5586 70.4269C88.2487 70.7368 87.8286 70.9111 87.3903 70.9116Z"
        :fill="primaryColors.primary500"
      />
      <path
        d="M42.9465 89.3089H19.1727C18.8781 89.3089 18.5956 89.1919 18.3873 88.9836C18.179 88.7753 18.062 88.4928 18.062 88.1982C18.062 87.9036 18.179 87.6211 18.3873 87.4128C18.5956 87.2045 18.8781 87.0875 19.1727 87.0875H42.9465C43.2411 87.0875 43.5236 87.2045 43.7319 87.4128C43.9402 87.6211 44.0572 87.9036 44.0572 88.1982C44.0572 88.4928 43.9402 88.7753 43.7319 88.9836C43.5236 89.1919 43.2411 89.3089 42.9465 89.3089Z"
        fill="#E6E6E6"
      />
      <path
        d="M35.3243 84.5336H26.7948C26.5002 84.5336 26.2177 84.4166 26.0094 84.2083C25.8011 84 25.6841 83.7175 25.6841 83.4229C25.6841 83.1284 25.8011 82.8459 26.0094 82.6376C26.2177 82.4293 26.5002 82.3123 26.7948 82.3123H35.3243C35.6189 82.3123 35.9014 82.4293 36.1097 82.6376C36.318 82.8459 36.435 83.1284 36.435 83.4229C36.435 83.7175 36.318 84 36.1097 84.2083C35.9014 84.4166 35.6189 84.5336 35.3243 84.5336Z"
        fill="#E6E6E6"
      />
      <path d="M103.736 50.892H13.189V51.2593H103.736V50.892Z" fill="#3F3D56" />
    </g>
    <defs>
      <clipPath id="ccs-clip0">
        <rect width="104.17" height="95.0122" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts">
const primaryColors = getPrimaryColors();
</script>
